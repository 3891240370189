import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PagedResult } from '../interfaces/paged-results.interface';
import { Team } from '../interfaces/team.interface';
import { Coach } from '../interfaces/coach.interface';

import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Group } from '../interfaces/group.interface';
import { Recipient } from '../interfaces/recipient.interface';
import { Invoice } from '../interfaces/invoice.interface';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  constructor(private http: HttpClient, private logger: NGXLogger) {}

  private _serviceUrl: string = environment.serviceUrl;

  getTeams(
    keyword: string,
    nextSet: number
  ): Observable<HttpResponse<PagedResult<Team>>> {
    const apiURL = `${this._serviceUrl}/teams/search/?keyword=${keyword}&nextSet=${nextSet}`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<PagedResult<Team>>(apiURL, { observe: 'response' });
  }

  getAllTeams(): Observable<HttpResponse<Team[]>> {
    const apiURL = `${this._serviceUrl}/teams/all/`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<Team[]>(apiURL, { observe: 'response' });
  }

  getMemberTeams(): Observable<HttpResponse<Team[]>> {
    const apiURL = `${this._serviceUrl}/teams/members/`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<Team[]>(apiURL, { observe: 'response' });
  }

  getTeam(id: string): Observable<HttpResponse<Team>> {
    const apiURL = `${this._serviceUrl}/teams/team/${id}`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<Team>(apiURL, { observe: 'response' });
  }

  getTeamFromCode(code: string): Observable<HttpResponse<Team>> {
    const apiURL = `${this._serviceUrl}/teams/team/code/${code}`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<Team>(apiURL, { observe: 'response' });
  }

  updateTeam(
    id: string,
    props: { [name: string]: string }
  ): Observable<HttpResponse<void>> {
    const apiURL = `${this._serviceUrl}/teams/team/${id}/update`;
    this.logger.info('service url: ', apiURL);
    return this.http.post<void>(apiURL, props, { observe: 'response' });
  }
  getAllCoachesForTeam(code: string): Observable<HttpResponse<Coach[]>> {
    const apiURL = `${this._serviceUrl}/coaches/team/${code}`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<Coach[]>(apiURL, { observe: 'response' });
  }

  getCoachesForTeam(
    code: string,
    keyword: string,
    onlyInActive: boolean,
    nextSet: number
  ): Observable<HttpResponse<PagedResult<Coach>>> {
    const onlyInactive = onlyInActive ? 'onlyInActive=true&' : '';
    const apiURL = `${this._serviceUrl}/coaches/team/${code}/search/?${onlyInactive}keyword=${keyword}&nextSet=${nextSet}`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<PagedResult<Coach>>(apiURL, { observe: 'response' });
  }

  getCoach(id: string): Observable<HttpResponse<Coach>> {
    const apiURL = `${this._serviceUrl}/coaches/coach/${id}`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<Coach>(apiURL, { observe: 'response' });
  }

  updateCoach(
    id: string,
    props: { [name: string]: string }
  ): Observable<HttpResponse<void>> {
    const apiURL = `${this._serviceUrl}/coaches/coach/${id}/update`;
    this.logger.info('service url: ', apiURL);
    return this.http.post<void>(apiURL, props, { observe: 'response' });
  }

  toggleCoach(id: string, isActive: boolean): Observable<HttpResponse<void>> {
    const apiURL = `${this._serviceUrl}/coaches/coach/${id}/update`;
    const payload = {
      isActive: isActive ? 'true' : 'false',
    };
    this.logger.info('service url: ', apiURL);
    return this.http.post<void>(apiURL, payload, { observe: 'response' });
  }

  createTeam(payload: any): Observable<HttpResponse<string>> {
    const apiURL = `${this._serviceUrl}/teams`;
    this.logger.info('service url: ', apiURL);
    return this.http.post<string>(apiURL, payload, { observe: 'response' });
  }

  createCoach(payload: any): Observable<HttpResponse<string>> {
    const apiURL = `${this._serviceUrl}/coaches`;
    this.logger.info('service url: ', apiURL);
    return this.http.post<string>(apiURL, payload, { observe: 'response' });
  }

  getCoachesIdsOnTeam(
    code: string
  ): Observable<HttpResponse<{ [id: string]: string }>> {
    const apiURL = `${this._serviceUrl}/coaches/team/${code}/names`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<{ [id: string]: string }>(apiURL, {
      observe: 'response',
    });
  }

  getAllGroups(code: string): Observable<HttpResponse<Group[]>> {
    const apiURL = `${this._serviceUrl}/groups/team/${code}`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<Group[]>(apiURL, { observe: 'response' });
  }

  getGroup(id: string): Observable<HttpResponse<Group>> {
    const apiURL = `${this._serviceUrl}/groups/group/${id}`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<Group>(apiURL, { observe: 'response' });
  }

  addSwimmersToGroup(
    id: string,
    swimmerIds: unknown[]
  ): Observable<HttpResponse<void>> {
    const apiURL = `${this._serviceUrl}/groups/group/${id}/swimmers/add`;
    this.logger.info('service url: ', apiURL);
    return this.http.post<void>(apiURL, swimmerIds, { observe: 'response' });
  }

  removeGroup(id: string): Observable<HttpResponse<void>> {
    const apiURL = `${this._serviceUrl}/groups/group/${id}/remove`;
    this.logger.info('service url: ', apiURL);
    return this.http.post<void>(apiURL, '', { observe: 'response' });
  }

  removeSwimmerFromGroup(
    id: string,
    swimmerId: string
  ): Observable<HttpResponse<void>> {
    const apiURL = `${this._serviceUrl}/groups/group/${id}/swimmer/${swimmerId}/remove`;
    this.logger.info('service url: ', apiURL);
    return this.http.post<void>(apiURL, '', { observe: 'response' });
  }

  addCoachesToGroup(
    id: string,
    ids: unknown[]
  ): Observable<HttpResponse<void>> {
    const apiURL = `${this._serviceUrl}/groups/group/${id}/coaches/add`;
    this.logger.info('service url: ', apiURL);
    return this.http.post<void>(apiURL, ids, { observe: 'response' });
  }

  removeCoachFromGroup(
    id: string,
    coachId: string
  ): Observable<HttpResponse<void>> {
    const apiURL = `${this._serviceUrl}/groups/group/${id}/coach/${coachId}/remove`;
    this.logger.info('service url: ', apiURL);
    return this.http.post<void>(apiURL, '', { observe: 'response' });
  }

  createGroup(payload: any): Observable<HttpResponse<string>> {
    const apiURL = `${this._serviceUrl}/groups`;
    this.logger.info('service url: ', apiURL);
    return this.http.post<string>(apiURL, payload, { observe: 'response' });
  }

  updateGroup(id: string, props: any): Observable<HttpResponse<void>> {
    const apiURL = `${this._serviceUrl}/groups/group/${id}/update`;
    this.logger.info('service url: ', apiURL);
    return this.http.post<void>(apiURL, props, { observe: 'response' });
  }

  getCoachRecipients(code: string): Observable<HttpResponse<Recipient[]>> {
    const apiURL = `${this._serviceUrl}/coaches/team/${code}/recipients`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<Recipient[]>(apiURL, {
      observe: 'response',
    });
  }

  removeCoach(id: string): Observable<HttpResponse<void>> {
    const apiURL = `${this._serviceUrl}/coaches/coach/${id}/remove`;
    this.logger.info('url: ', apiURL);
    return this.http.post<void>(apiURL, '', { observe: 'response' });
  }

  getInvoices(
    code: string,
    keyword: string,
    nextSet: number
  ): Observable<HttpResponse<PagedResult<Invoice>>> {
    const apiURL = `${this._serviceUrl}/invoices/team/${code}/search?keyword=${keyword}&nextSet=${nextSet}`;
    this.logger.info('service url: ', apiURL);
    return this.http.get<PagedResult<Invoice>>(apiURL, { observe: 'response' });
  }
}
