<div class="logo">
  <a class="logo-normal">
    <div class="logo-img">
      <img src="/assets/img/logos/warrior_logo_horiz.png" />
    </div>
  </a>
  <a class="logo-mini">
    <div class="logo-img-small">
      <img src="/assets/img/logos/warrior_logo_100px.png" />
    </div>
  </a>
</div>

<div class="sidebar-wrapper">
  <div class="user">
    <div class="photo">
      <img src="{{ p?.photo }}" />
    </div>
    <div class="user-info">
      <a>
        <span>
          {{ p?.name }}
        </span>
      </a>
      <div class="collapse" id="collapseExample">
        <ul class="nav">
          <!--
          <li class="nav-item">
            <a href="#" class="nav-link" [routerLink]="'/settings'">
              <span class="sidebar-mini"><i class="material-icons">settings</i></span>
              <span class="sidebar-normal">Settings</span>
            </a>
          </li>-->
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="isMobileMenu()">
    <ul class="nav navbar-nav nav-mobile-menu">
      <li class="nav-item">
        <a class="nav-link" href="#pablo">
          <i class="material-icons">person</i>
          <p>
            <span class="d-lg-none d-md-block">Account</span>
          </p>
        </a>
      </li>
    </ul>
  </div>
  <ul class="nav">
    <li
      *ngFor="let menuitem of menuItems"
      routerLinkActive="active"
      class="nav-item">
      <!--If is a single link-->

      <a
        [routerLink]="[menuitem.path]"
        *ngIf="
          menuitem.type === 'link' &&
          features.isEnabled(menuitem.flag) &&
          hasPermission(menuitem.permission)
        "
        class="nav-link">
        <i class="material-icons">{{ menuitem.icontype }}</i>
        <p>
          {{ menuitem.title }}
        </p>
      </a>
      <!--If it have a submenu-->
      <a
        data-toggle="collapse"
        href="#{{ menuitem.collapse }}"
        *ngIf="
          menuitem.type === 'sub' &&
          features.isEnabled(menuitem.flag) &&
          hasPermission(menuitem.permission)
        "
        (click)="updatePS()"
        class="nav-link">
        <i class="material-icons">{{ menuitem.icontype }}</i>
        <p>
          {{ menuitem.title }}<b class="caret"></b>
          <img *ngIf="menuitem.isBeta" src="assets/img/beta.png" class="beta" />
        </p>
      </a>

      <!--Display the submenu items-->
      <div
        id="{{ menuitem.collapse }}"
        class="collapse"
        *ngIf="menuitem.type === 'sub' && features.isEnabled(menuitem.flag)">
        <ul class="nav">
          <li
            routerLinkActive="inactive"
            *ngFor="let childitem of menuitem.children"
            class="nav-item">
            <a
              *ngIf="hasPermission(childitem.permission)"
              [routerLink]="[menuitem.path + '/' + childitem.path]"
              class="nav-link nav-link-sub">
              <i *ngIf="childitem.icontype" class="material-icons">{{
                childitem.icontype
              }}</i>
              <span *ngIf="!childitem.icontype" class="sidebar-mini">{{
                childitem.ab
              }}</span>
              <span class="sidebar-normal">{{ childitem.title }}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
