import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { jwtDecode } from 'jwt-decode';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TeamService } from '../../config/services/team.service';
import { DOCUMENT } from '@angular/common';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-layout',
  templateUrl: './auth-layout.component.html',
})
export class AuthLayoutComponent implements OnInit {
  private toggleButton: any;
  private sidebarVisible: boolean;
  mobile_menu_visible: any = 0;
  private _router: Subscription;
  private _isLogin = false;
  profileJson: string = null;

  constructor(
    public auth: AuthService,
    public teamService: TeamService,
    private logger: NGXLogger,
    private router: Router,
    private route: ActivatedRoute,
    private element: ElementRef,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.sidebarVisible = false;
  }

  isAuthenticated$ = this.auth.isAuthenticated$;

  onLogin() {
    this.auth.loginWithRedirect({
      //redirect_uri:
      //  window.location.protocol +
      //  '//' +
      //  window.location.host +
      //  '#/login?success=true',
      fragment: '#/login?success=true',
    });
  }
  onLogout() {
    this.auth.logout();
  }

  ngOnInit() {
    console.info('auth-layout:', this.route.queryParams);
    this.route.queryParams.subscribe(params => {
      console.log('params: ', params);
      //this._isLogin = params && params.success ? true : false;

      this._isLogin = true;

      let permissions: string[] = [];
      if (this._isLogin) {
        this.auth.getAccessTokenSilently().subscribe(token => {
          this.logger.info('token', token);
          const tokenInfo = this.getDecodedAccessToken(token); // decode token

          permissions = tokenInfo.permissions;
        });

        this.auth.idTokenClaims$.subscribe(claims => {
          this.logger.info('claims', claims);
          const roles = claims['http://swimwarrior.com/roles']
            ? claims['http://swimwarrior.com/roles']
            : [];
          const userData = claims['http://swimwarrior.com/userdata']
            ? claims['http://swimwarrior.com/userdata']
            : {};

          const appData = claims['http://swimwarrior.com/appdata']
            ? claims['http://swimwarrior.com/appdata']
            : {};

          //read the team code from token data so we can fetch the team record
          //TODO: add caching to the team / profile
          let teamCode = appData.team.code;
          let photo = claims['picture'];
          this.teamService.getTeamFromCode(teamCode).subscribe({
            next: data => {
              const team = {
                code: data.body.code,
                name: data.body.name,
                state: data.body.location ? data.body.location.state : 'AZ',
                timezone: data.body.timeZone
                  ? data.body.timeZone
                  : 'America/Phoenix',
                events: data.body.events,
              };
              const profile = {
                id: userData.id ?? claims['user_id'],
                swimWarriorId: appData.swimWarriorId ?? '',
                name: claims['name'],
                firstName: claims['given_name'],
                lastName: claims['family_name'],
                email: userData.email ?? '',
                photo: userData.photo,
                phone: userData.phone ?? '',
                gender: userData.gender ?? '',
                birthDate: userData.birthDate ?? '',
                role: roles[0],
                team: team,
                permissions: permissions,
              };

              this.logger.info('team', team);
              localStorage.setItem('team', JSON.stringify(team));
              this.logger.info('profile', profile);
              localStorage.setItem('profile', JSON.stringify(profile));
              return this.router.navigate([`/dashboard`]);
            },
          });
        });
      }
    });

    const navbar: HTMLElement = this.element.nativeElement;

    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    this._router = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.sidebarClose();
        const $layer = document.getElementsByClassName('close-layer')[0];
        if ($layer) {
          $layer.remove();
        }
      });
  }
  sidebarOpen() {
    var $toggle = document.getElementsByClassName('navbar-toggler')[0];
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);
    body.classList.add('nav-open');
    setTimeout(function () {
      $toggle.classList.add('toggled');
    }, 430);

    var $layer = document.createElement('div');
    $layer.setAttribute('class', 'close-layer');

    if (body.querySelectorAll('.wrapper-full-page')) {
      document
        .getElementsByClassName('wrapper-full-page')[0]
        .appendChild($layer);
    } else if (body.classList.contains('off-canvas-sidebar')) {
      document
        .getElementsByClassName('wrapper-full-page')[0]
        .appendChild($layer);
    }

    setTimeout(function () {
      $layer.classList.add('visible');
    }, 100);

    $layer.onclick = function () {
      //asign a function
      body.classList.remove('nav-open');
      this.mobile_menu_visible = 0;
      this.sidebarVisible = false;

      $layer.classList.remove('visible');
      setTimeout(function () {
        $layer.remove();
        $toggle.classList.remove('toggled');
      }, 400);
    }.bind(this);

    body.classList.add('nav-open');
    this.mobile_menu_visible = 1;
    this.sidebarVisible = true;
  }
  sidebarClose() {
    var $toggle = document.getElementsByClassName('navbar-toggler')[0];
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton.classList.remove('toggled');
    var $layer = document.createElement('div');
    $layer.setAttribute('class', 'close-layer');

    this.sidebarVisible = false;
    body.classList.remove('nav-open');
    // $('html').removeClass('nav-open');
    body.classList.remove('nav-open');
    if ($layer) {
      $layer.remove();
    }

    setTimeout(function () {
      $toggle.classList.remove('toggled');
    }, 400);

    this.mobile_menu_visible = 0;
  }
  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }
}
